

































import Component from 'vue-class-component'
import InquiryPageLayout from '@/views/layouts/InquiryPageLayout.vue'
import { Action } from 'vuex-class'
import { ACCEPT_DECLINE, INQUIRIES_FOR_COMPANY } from '@/store/inquiry/actions'
import { InquiryDto } from '@/store/inquiry/types'
import { GenericRequest, SimpleServerResponse, TalentProfileViewType } from '@/store/types'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { SortingInfo } from '@/store/commonFormInput/types'
import {
  ACCEPT,
  getAcceptDeclinePositiveFeedback,
  getCompanyInquiryConfirmationHeadingForStudent,
  getTalentRequestConfirmationExplanationForCompany
} from '@/common/inquiry'
import { GET_TALENT_VIEW_BY_JOB_AD } from '@/store/jobAd/actions'
import { prepareCvDownload } from '@/common/cvLayout'
import { Dictionary } from 'vue-router/types/router'
import MetaComponent from '@/common/MetaComponent.vue'
@Component({
  components: { InquiryPageLayout }
})
export default class JobAdApplications extends MetaComponent {
  showErrorMessage = false
  isLoading = false
  errorMessage = ''
  private modalHeading = ''
  private modalExplanation = ''

  @Action(INQUIRIES_FOR_COMPANY)
  public getInquiries: (request: GenericRequest) => Promise<InquiryDto[]>

  @Action(ACCEPT_DECLINE)
  public acceptOrDecline: (dto: GenericRequest) => Promise<SimpleServerResponse>

  @Action(GET_TALENT_VIEW_BY_JOB_AD)
  public getTalentViewByJobAd: (dto: GenericRequest) => Promise<TalentProfileViewType>

  private openInquiries: Array<InquiryDto> = [];
  private acceptedInquiries: Array<InquiryDto> = [];
  private declinedInquiries: Array<InquiryDto> = [];

  get canReadInquiries (): boolean {
    return hasRight(Rights.INQUIRY_READ_BY_COMPANY)
  }

  get sortable (): SortingInfo[] {
    return [
      { key: 'talentFirstName', label: this.$i18n.t('personal-information.firstName').toString() },
      { key: 'talentLastName', label: this.$i18n.t('personal-information.lastName').toString() },
      { key: 'inquiryModified', label: this.$i18n.t('inquiries.request-date').toString() },
      { key: 'jobAdTitle', label: this.$i18n.tc('job-ad.job-ad', 1).toString() }
    ]
  }

  openConfirmationModal (desiredAction: string): void {
    this.modalExplanation = getTalentRequestConfirmationExplanationForCompany(desiredAction)
    this.modalHeading = getCompanyInquiryConfirmationHeadingForStudent(desiredAction)
  }

  showTalent (selectedInquiry: InquiryDto): void {
    const params: Dictionary<string> = {
      id: selectedInquiry.talentId,
      jobAdId: selectedInquiry.jobAdId
    }

    if (selectedInquiry.inquiryStatus) {
      params.inquiryButton = selectedInquiry.inquiryStatus as string
    }

    if (selectedInquiry.inquiryId) {
      params.inquiryId = selectedInquiry.inquiryId as string
    }

    if (selectedInquiry.cvLayout) {
      params.cvLayout = selectedInquiry.cvLayout as string
    }

    this.$router.push({
      name: 'talent-profile-jobad',
      params: params
    })
  }

  downloadCvAsPdf (selectedInquiry: InquiryDto): void {
    this.getTalentViewByJobAd({ query: [selectedInquiry.jobAdId, selectedInquiry.talentId] }).then(data => {
      const doc = prepareCvDownload(selectedInquiry.cvLayout ? selectedInquiry.cvLayout : '', data)
      window.open(doc.output('bloburl').toString(), '_blank')
    })
  }

  inquiryResponse (selectedInquiry: InquiryDto, desiredAction: string): void {
    if (selectedInquiry.inquiryId !== undefined) {
      const dto: GenericRequest = { query: [], params: undefined }
      if (desiredAction === ACCEPT) {
        dto.query.splice(0, 1, 'true')
      } else {
        dto.query.splice(0, 1, 'false')
      }
      dto.query[1] = selectedInquiry.inquiryId as string
      this.$root.$emit('load')
      this.acceptOrDecline(dto).then(() => {
        switch (selectedInquiry.inquiryStatus) {
          case Enums.OPEN_INQUIRY_TO_COMPANY: {
            this.openInquiries.splice(this.openInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
            break
          }
          case Enums.INQUIRY_ACCEPTED_BY_COMPANY: {
            this.acceptedInquiries.splice(this.acceptedInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
            break
          }
          case Enums.INQUIRY_DENIED_BY_COMPANY: {
            this.declinedInquiries.splice(this.declinedInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
            break
          }
        }
        const message = getAcceptDeclinePositiveFeedback(desiredAction)
        if (desiredAction === ACCEPT) {
          selectedInquiry.inquiryStatus = Enums.INQUIRY_ACCEPTED_BY_COMPANY
          this.acceptedInquiries.push(selectedInquiry)
        } else {
          selectedInquiry.inquiryStatus = Enums.INQUIRY_DENIED_BY_COMPANY
          this.declinedInquiries.push(selectedInquiry)
        }
        this.$root.$emit('alert', message, '', false)
      }).catch(() => {
        this.$root.$emit('alert', this.$i18n.t('warning.general-error-message').toString(), '', true)
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    }
  }

  created (): void {
    if (this.canReadInquiries) {
      this.isLoading = true
      Promise.all([
        this.getInquiries({ query: [Enums.OPEN_INQUIRY_TO_COMPANY] }),
        this.getInquiries({ query: [Enums.INQUIRY_ACCEPTED_BY_COMPANY] }),
        this.getInquiries({ query: [Enums.INQUIRY_DENIED_BY_COMPANY] })
      ]).then((data) => {
        this.openInquiries = data[0]
        this.acceptedInquiries = data[1]
        this.declinedInquiries = data[2]
      }).finally(() => {
        this.isLoading = false
      })
    } else {
      if (!this.canReadInquiries) {
        this.showErrorMessage = true
        this.errorMessage = this.$t('warning.missing-rights').toString()
      } else {
        this.showErrorMessage = true
        this.errorMessage = this.$t('warning.general-error-message').toString()
      }
    }
  }
}
